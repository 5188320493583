<template>
  <div>
    <div class="d-flex justify-center flex-wrap">
      <v-btn
        v-for="dateFilter in dateFilters"
        :key="dateFilter.value"
        small
        @click="
          emitFrom(dateFilter.from);
          emitTo(dateFilter.to);
        "
        :text="!active(dateFilter)"
        :color="activeDate(dateFilter) ? 'primary' : 'secondary'"
        class="text-capitalize mx-1 my-1"
        :class="'v-btn--active'"
      >
        {{ dateFilter.text }}
      </v-btn>
    </div>
    <div class="d-flex mt-4" v-if="from && to">
      <date-field append-icon="" :value="from" @input="emitFrom" label="From" dense></date-field>
      <span class="mx-1 mt-2"> - </span>
      <date-field dense append-icon="" :value="to" @input="emitTo" label="To"></date-field>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { sort } from '../utils';
const fmt = 'YYYY-MM-DD';
export default {
  props: {
    from: {
      type: String,
      default: null
    },
    to: {
      type: String,
      default: null
    },
    dates: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: {
    DateField: () => import('./Core/DateField.vue')
  },
  computed: {
    dateFilters() {
      const filters = [
        {
          text: '90 days',
          from: moment().subtract('90', 'days').format(fmt),
          to: moment().format(fmt),
          showPastLabel: true
        }
      ];

      let years = [
        {
          text: moment().format('YYYY'),
          from: moment().startOf('year').format(fmt),
          to: moment().endOf('year').format(fmt)
        },
        {
          text: moment().subtract(1, 'year').format('YYYY'),
          from: moment().subtract(1, 'year').startOf('year').format(fmt),
          to: moment().subtract(1, 'year').endOf('year').format(fmt)
        }
      ];

      (this.dates || []).forEach(year => {
        const exclude = years.map(i => i.text);
        if (!exclude.includes(moment(year).format('YYYY'))) {
          const momentYear = moment(year);
          const y = {
            text: momentYear.format('YYYY'),
            from: momentYear.startOf('year').format(fmt),
            to: momentYear.endOf('year').format(fmt)
          };
          years.push(y);
        }
      });

      years = sort({
        items: years,
        generator: i => {
          return Number(i.text);
        }
      });

      filters.push(...years);

      filters.push({
        text: 'All',
        from: '',
        to: ''
      });

      return filters;
    }
  },
  data() {
    return {
      today: moment().format('YYYY-MM-DD')
    };
  },
  methods: {
    activeDate({ from, to, text }) {
      if (text === 'All') return this.dates.length;
      let res = false;
      try {
        this.dates.forEach(date => {
          const start = moment(from);
          const end = moment(to);
          const d = moment(date);
          if (d.isSameOrAfter(start) && d.isSameOrBefore(end)) {
            res = true;
          }
        });
      } catch {
        //
      }
      return res;
    },
    active(filter) {
      return filter.from === this.from && filter.to === this.to;
    },
    emitTo(value) {
      this.$emit('update:to', value);
    },
    emitFrom(value) {
      this.$emit('update:from', value);
    }
  }
};
</script>

<style>
</style>
